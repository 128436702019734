.header-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: fixed;
	left: 0;
	top: 0;
	padding: 0 15px 0 15px;
	height: 60px;
	background: #fff;
	z-index: 999;
	min-width: 100vw;

	.header-item {
		display: flex;

		.logo {
			margin-right: 19px;
			width: 81px;
		}

		&>h3 {
			margin: 5px 0 0 0;
			color: #299445;
			font-size: 18px;
			text-transform: uppercase;

			span {
				display: block;
				color: $dark;
				font-size: 16px;
			}
		}
	}

	.head-uid {
		&>h3 {
			display: block;
			color: $dark;
			font-size: 16px;
			margin: 0;
		}
	}
}
