.receipt-container {
	border-style: ridge;
	height: 330px;
	min-width: 400px;
	overflow: auto;

	.spinner-container {
		margin-top: 3rem;
		display: flex;
		justify-content: center;
	}
}

//ReceiptModal
.receiptmodal-container {
	display: flex;
	flex-direction: column;
	padding: 1rem;
	font: small-caption;
	font-weight: bold;

	.receipt-row {
		display: flex;
		flex-direction: row;
		justify-content: center;
	}

	.receipt-row-item {
		display: flex;
		justify-content: space-between;
	}

	.item-borderbottom {
		border-bottom: double;
	}

	.item-bordertop {
		border-top: double;
	}
}
