// MENU
.side-menu {
	position: fixed;
	top: 60px;
	left: 0;
	width: 100px; // should match with default.scss main
	height: calc(100% - 60px);
	min-height: 600px;
	background: #149c4a;
	text-align: center;

	ul {
		padding: 0;
		height: 90%;

		li {
			display: flex;
			width: 100%;
			height: 22%;
			align-items: center;
			font-size: $font-size-lg;
			line-height: 1.5;
			border-bottom: 1px solid #6de39c;

			.side-menu-item {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				background: none;
			}

			a {
				width: 100%;
				height: 100%;
				color: #fff;
				font-size: 24px;
				line-height: 100%;
				text-align: center;
			}

			&:last-child {
				border: none;
			}

			&:hover, &:focus, &.active {
				background: #9aca3c;
			}
		}
	}

	.version {
		color: #fff;
		font-size: 10px;
	}
}
