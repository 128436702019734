.summary-container {
	padding: 0 90px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.summery-basic {
	padding: 15px;
	flex-grow: 1;

	p {
		font-size: 14px;
		line-height: 22px;
		margin-bottom: 0;
		color: #000;

		>label {
			color: #676767;
			font-size: 13px;
			line-height: 22px;
			margin: 0;
			width: 70px;
		}
	}

	.lg-txt {
		margin-bottom: 2rem;
		font-weight: 500;
		font-size: 20px;
		line-height: 22px;
	}
}

.pump-card.summary-pump {
	height: 140px;
	justify-content: center;
	flex-basis: 150px;

	img {
		width: 70px;
		margin-bottom: 0;
	}

	.sticker {
		border-top: 60px solid #9bca3b;
		border-left: 60px solid transparent;

		span {
			top: -56px;
			left: -37px;
			font-size: 16px;
		}
	}
}

.summary-item {
	img {
		width: 40px;
		margin: 0 10px;
	}
}

.summary-mop {
	font-size: 13px;
	line-height: 22px;
	color: #676767;
	letter-spacing: 0.24px;

	strong {
		color: #000;
		font-size: 15px;
	}
}

.summary-mop-change {
	button {
		font-size: 13px;
		line-height: 22px;
		letter-spacing: 0.24px;
	}
}

.summary-block {
	width: 100%;
	margin: 10px;
	padding: 15px;

	.table {
		&>thead {
			th {
				border: none;
				border-bottom: 1px solid #dee2e6;
				font-size: 13px;
				line-height: 22px;
				color: #676767;
				letter-spacing: 0.24px;

				&:nth-child(1) {
					text-align: left;
					padding-left: 40px;
				}
			}
		}

		td {
			border: none;
			border-bottom: 1px solid #dee2e6;
			font-size: 16px;
			line-height: 24px;
			letter-spacing: 0.24px;
			color: #000;
			vertical-align: middle;

			&:nth-child(1) {
				text-align: left;
			}
		}

		&>tfoot {
			th {
				padding-top: 30px;

				strong {
					font-weight: 500;
					font-size: 20px;
					line-height: 22px;
					color: #000;
				}
			}
		}
	}
}
