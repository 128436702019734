body {
	overflow-x: hidden;
	overflow-y: auto;
	height: 100%;
	background: #eaeef2;
	font-style: normal;
	font-weight: normal;
	font-family: $font-family-base;
	font-size: 1rem;
	line-height: 1.25rem;
}

::-webkit-scrollbar {
	width: 5px;
}

::-webkit-scrollbar-track {
	background: #ddd;
}

::-webkit-scrollbar-thumb {
	background: #149c4a;
}

img {
	@extend .img-fluid;
}

.app-init-loader {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 400px;
	justify-content: center;

	&>p {
		margin-top: 1rem;
	}
}

.btn-card-single {
	margin: 0;
	font-size: 14px;
	background: #e4ffaf;
	border: 1px solid #e6edd7;

	span {
		margin: 0 5px 0 0;
		color: #149c4a;
		font-size: 12px;
		display: inline-block;
		width: 24px;
		height: 24px;
		line-height: 24px;
		background: #fff;
		border-radius: 50%;
	}
}

.form-control {
	font-size: 14px !important;
}

.input-group-append {
	.input-group-text {
		font-size: 14px;
	}
}

.section-title {
	margin: 20px 0 0;
	font-size: 24px;
	font-weight: 500;
	text-transform: uppercase;
}

.title-lg {
	margin: 0;
	color: #149c4a;
	font-size: 26px;
	font-weight: 500;
	text-transform: uppercase;
}

.col-2dot4, .col-sm-2dot4, .col-md-2dot4, .col-lg-2dot4, .col-xl-2dot4 {
	position: relative;
	width: 100%;
	min-height: 1px;
	padding-right: 15px;
	padding-left: 15px;
}

.col-2dot4 {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 20%;
	flex: 0 0 20%;
	max-width: 20%;
}

@media (min-width: 540px) {
	.col-sm-2dot4 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 20%;
		flex: 0 0 20%;
		max-width: 20%;
	}
}

@media (min-width: 720px) {
	.col-md-2dot4 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 20%;
		flex: 0 0 20%;
		max-width: 20%;
	}
}

@media (min-width: 960px) {
	.col-lg-2dot4 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 20%;
		flex: 0 0 20%;
		max-width: 20%;
	}
}

@media (min-width: 1140px) {
	.col-xl-2dot4 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 20%;
		flex: 0 0 20%;
		max-width: 20%;
	}
}

.col-2dot4, .col-sm-2dot4, .col-md-2dot4, .col-lg-2dot4, .col-xl-2dot4 {
	position: relative;
	width: 100%;
	min-height: 1px;
	padding-right: 15px;
	padding-left: 15px;
}

.col-2dot4 {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 20%;
	flex: 0 0 20%;
	max-width: 20%;
}

@media (min-width: 540px) {
	.col-sm-2dot4 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 20%;
		flex: 0 0 20%;
		max-width: 20%;
	}
}

@media (min-width: 720px) {
	.col-md-2dot4 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 20%;
		flex: 0 0 20%;
		max-width: 20%;
	}
}

@media (min-width: 960px) {
	.col-lg-2dot4 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 20%;
		flex: 0 0 20%;
		max-width: 20%;
	}
}

@media (min-width: 1140px) {
	.col-xl-2dot4 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 20%;
		flex: 0 0 20%;
		max-width: 20%;
	}
}

.scroll {
	position: relative;
	right: -10px;

	&::-webkit-scrollbar {
		width: 5px;
	}

	&::-webkit-scrollbar-track {
		background: #ddd;
	}

	&::-webkit-scrollbar-thumb {
		background: #149c4a;
	}
}

// CONTENT
main {
	margin: 60px 0 0 100px; // 100px = sidemenu width
	padding: 20px 5px;

	.home-row {
		position: absolute;
		top: 0;
		width: 100%;
	}

	.homenew-row {
		overflow: hidden;
		height: calc(100vh - 170px);
	}

	.info {
		max-height: calc(100vh - 250px);
		overflow-y: auto;

		.customer-details {
			p {
				position: relative;
				margin: 0;
				padding: 0 0 10px;
				color: #898989;
				font-size: 18px;

				span {
					position: absolute;
					left: 150px;
					margin: 0;
					text-align: center;

					&.last {
						position: absolute;
						left: 200px;
					}
				}
			}
		}
	}

	.nozzle-card {
		position: relative;
		height: 100px;
		flex-direction: row;
		padding: 10px;
		align-items: center;
		display: flex;
		border-bottom: 1px solid rgb(211, 210, 210);
		box-sizing: border-box;

		.icon-color {
			font-size: 50px;

			&.red {
				color: rgba(255, 0, 0);
			}

			&.green {
				color: rgba(0, 128, 0);
			}

			&.blue {
				color: rgba(0, 0, 128);
			}

			&.yellow {
				color: rgba(255, 255, 0);
			}

			&.black {
				color: rgba(0, 0, 0);
			}
		}

		.nozzle-block {
			flex-basis: 100px;
			margin-left: 10px;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			display: flex;

			.circle {
				display: flex;
				width: 35px;
				height: 35px;
				border-radius: 50%;
				border: 1px solid black;

				&>h5 {
					margin: auto;
				}
			}

			&>span {
				font-weight: 400;
				font-size: 12px;
				line-height: 25px;
				letter-spacing: 0px;
				color: #7e7e7e;
			}

			&>strong {
				letter-spacing: 0px;
				color: #000000;
				font-weight: 500;
				font-size: 1rem;
				line-height: 25px;
			}
		}
	}

	.product {
		.pch-title {
			margin: 0 0 5px;
			color: #000;
			font-size: 16px;
			font-weight: 500;
		}

		.product-list {
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			grid-gap: 5px;

			a {
				position: relative;
				display: block;

				img {
					padding: 5px;
					width: 100%;
					border: 1px solid #f1f1f1;
				}

				.overlay {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					text-align: center;
					background: rgba(0, 0, 0, 0.4);
					opacity: 0;

					transition: all ease-in-out 0.5s;

					h6 {
						margin: 38% 0 0;
						color: #fff;
						font-size: 12px;
						font-weight: 400;
					}
				}

				&:hover {
					.overlay {
						opacity: 1;
					}
				}
			}
		}
	}

	.fuel {
		width: 100%;
		margin: 0 15px 15px 15px;

		// background-color: white;
		h4 {
			margin: 0;
			padding: 0;
			font-size: 26px;
			font-weight: 400;
			text-transform: uppercase;
		}

		.fuel-box {
			gap: 30px;

			// flex-direction: column;
			// margin: 20px 15px 15px 15px;
			// display: grid;
			// grid-gap: 5vw;
			// grid-template-columns: repeat(3, 1fr);
			.category {
				text-align: center;
				color: white;
				padding: 10px;

				// height: calc(100vh - 460px);
				.icon {
					//   margin: 51px auto;
					height: 100%;
					width: 60px;
				}

				h4 {
					margin: 10px 0;
					padding: 0;
					color: #fff;
					font-size: 18px;
					font-weight: 500;
				}

				p {
					margin: 0 0 20px;
					padding: 0;
					color: #fff;
					font-size: 16px;
					font-weight: 300;
				}

				&:nth-child(1) {
					background: #00a655;
				}

				&:nth-child(2) {
					background: #00419b;
				}

				&:nth-child(3) {
					background: #cc1a19;
				}

				&:nth-child(4) {
					background: #a67b00;
				}

				&.disabled {
					background: #9a9a9a !important;
				}
			}
		}
	}

	.payment {
		.payment-details {
			margin: 0;
			padding: 0 12px 0 0;
			height: 60px;
			overflow-y: auto;

			ul {
				margin: 0;
				padding: 0;
				list-style: none;

				li {
					padding: 20px 0;
					display: block;
					border-bottom: 1px solid #ced4da;

					.pro-details {
						position: relative;
						margin: 0 0 0 100px;

						img {
							position: absolute;
							top: 0;
							left: -100px;
							width: 80px;
							height: 80px;
						}

						h4 {
							position: relative;
							margin: 0 0 25px;
							padding: 0;
							font-size: 22px;
							font-weight: 500;

							span {
								position: absolute;
								top: -1px;
								right: 0;
								color: #707070;
								font-size: 22px;
								font-weight: 600;

								i {
									margin-right: 5px;
								}
							}
						}

						.input-group {
							width: 90px;

							input {
								padding: 5px 0;
								font-size: 16px;
								width: 30px;
								height: 30px;
								text-align: center;

								&:focus {
									border-color: #ced4da;
									box-shadow: none;
								}
							}

							.input-group-prepend {
								.btn {
									padding: 0;
									width: 30px;
									height: 30px;
									color: #55689d;
									font-size: 10px;
									font-weight: 600;
									line-height: 21px;
									border: 1px solid #ced4da;

									&.left {
										border-top-left-radius: 25px;
										border-bottom-left-radius: 25px;
									}

									&.right {
										margin-left: -1px;
										border-top-right-radius: 25px;
										border-bottom-right-radius: 25px;
									}

									&:hover,
									&:focus {
										color: #fff;
										background: #0f1e4c;
										border-color: #0f1e4c;
										box-shadow: none;
									}
								}
							}
						}

						.badge {
							position: absolute;
							left: 90px;
							bottom: 2px;
							padding: 4px 6px;
							font-size: 12px;
							font-weight: normal;
							border-radius: 0;
						}
					}

					&:last-child {
						padding-bottom: 0;
						border: none;
					}
				}
			}
		}
	}

	.offer {
		img {
			width: 100%;
			max-height: 250px;
		}
	}

	.calculator {
		border: none;
		border-radius: 0;

		.calculator-screen {
			padding-right: 20px;
			padding-left: 10px;
			width: 100%;
			height: 137px;
			background-color: #fff;
			color: #000;
			font-size: 36px;
			line-height: 137px;
			text-align: right;
			border: none;
		}

		.calculator-keys {
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			grid-gap: 1px;
			padding: 0;

			a,
			button {
				height: 80px;
				font-size: 24px;
				border: none;
				border-radius: 0;

				&.operator {
					color: #fff !important;
					background: #ff6d6c !important;
				}

				&.btn {
					color: #b0b0b0;
					background: #f1f1f1;
				}

				&:focus {
					box-shadow: none;
				}
			}
		}
	}

	.payment-option {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 1px;
		box-shadow: 0 10px 25px 0 rgba(50, 50, 93, 0.07), 0 5px 15px 0 rgba(0, 0, 0, 0.07);

		button {
			padding: 15px;
			height: 80px;
			font-size: 18px;
			text-align: left;
			border: none;
			border-radius: 0;

			&.btn {
				color: #fff;
				background: #149c4a;

				&:hover,
				&:focus {
					background: #9aca3c;
					box-shadow: none;
				}
			}

			i {
				margin-right: 15px;
			}
		}
	}

	.card {
		border: none;
		border-radius: 0;
		box-shadow: 0 10px 25px 0 rgba(50, 50, 93, 0.07), 0 5px 15px 0 rgba(0, 0, 0, 0.07);

		&.card-fix {
			min-height: 540px;
			height: 540px;

			@media (max-width: 768px) {
				margin-bottom: 20px;
				min-height: 100%;
				height: auto;
			}
		}

		.card-header {
			background: #fff;
			border: none;

			h4 {
				margin: 0;
				padding: 0;
				color: #000;
				font-size: 26px;
				font-weight: 400;
				text-transform: uppercase;
			}

			.input-group {
				.form-control {
					font-size: 12px;
					height: 28px;
					border-right: 0;
					border-top-left-radius: 25px;
					border-bottom-left-radius: 25px;

					&:focus {
						border-color: #ced4da;
						box-shadow: none;
					}
				}

				.input-group-prepend {
					.btn {
						padding: 0 10px;
						color: #149c4a;
						font-size: 14px;
						border-color: #ced4da;
						border-top-right-radius: 25px;
						border-bottom-right-radius: 25px;

						&.btn-right {
							border-left: 0;
						}

						&.trans {
							border: none;
							border-radius: 0;
						}

						&:hover,
						&:focus {
							color: #9aca3c;
							background: transparent;
							box-shadow: none;
						}
					}
				}
			}

			&.card-header-arrow {
				position: relative;
				padding: 0;
				max-height: 60px;

				.hct {
					position: absolute;
					top: 0;
					left: 60px;
					right: 60px;
					text-align: center;

					h2 {
						margin: 16px 0;
						font-size: 24px;
						font-weight: 400;
						text-transform: uppercase;

						span {
							color: #149c4a;
						}
					}
				}

				.arrow-left {
					float: left;
					color: #149c4a;
					width: 60px;
					height: 60px;
					font-size: 26px;
					line-height: 60px;
					text-align: center;

					&:hover,
					&:focus {
						color: #9aca3c;
					}
				}

				.arrow-right {
					float: right;
					color: #149c4a;
					width: 60px;
					height: 60px;
					font-size: 26px;
					line-height: 60px;
					text-align: center;

					&:hover,
					&:focus {
						color: #9aca3c;
					}
				}
			}
		}

		.card-body {
			&.card-body-fix {
				position: relative;
				padding: 0;
				max-height: 480px;

				.pro-carousel {
					.row {
						margin-left: 0.5rem;
						margin-right: 0.5rem;
					}
				}

				.print {
					background: #fff;
				}

				.fuel-nav {
					position: absolute;
					left: 0;
					right: 0;
					bottom: 0;

					.row {
						margin-left: 0.5rem;
						margin-right: 0.5rem;
					}
				}

				@media (max-width: 768px) {
					max-height: 100%;

					.fuel-nav {
						position: relative;
					}
				}
			}

			&.card-body-bg {
				padding: 0 1rem;

				.pro-carousel {
					padding: 0 0 20px;
					background: #d3d9db;

					.sub-head {
						position: relative;
						padding: 20px 0;
						display: block;
						text-align: center;
						border-bottom: 1px solid #c7c7c9;

						.num {
							position: absolute;
							left: 0;
							right: 0;
							bottom: -33px;
							margin: 0 auto;
							background: #149c4a;
							width: 26px;
							height: 26px;
							color: #fff;
							font-size: 12px;
							font-weight: 400;
							line-height: 27px;
							border-radius: 50%;
							z-index: 9;
						}

						.row {
							margin-left: 0.5rem;
							margin-right: 0.5rem;

							.form-check {
								position: relative;
								padding: 0;

								.form-check-input {
									position: relative;
									margin-left: 10px;
								}

								label {
									color: #000;
									font-size: 14px;
								}
							}

							.btn-authorize {
								position: absolute;
								top: 13px;
								left: 8px;
								font-size: 14px;
								font-weight: 500;
								background: #fff8b4;

								span {
									margin-right: 5px;
									color: #149c4a;
								}

								&:hover {
									background: #f7f0ae;
								}

								@media (max-width: 678px) {
									position: relative;
									left: 0;
									margin: 0 0 15px;
								}
							}
						}
					}

					.nav-tabs {
						margin-top: 20px;
						border: none;

						.nav-item {
							margin: 0 10px;

							.nav-link {
								color: #000;
								font-size: 14px;
								background: #fff;
								border: none;
								border-radius: 4px;

								span {
									margin-right: 20px;
								}

								&:hover, &:focus, &.active {
									color: #fff;
									background: #149c4a;
								}
							}

							&:first-child {
								margin-left: 0;
							}

							&:last-child {
								margin-right: 0;
							}

							@media (max-width: 768px) {
								margin: 0 0 10px;

								&:last-child {
									margin: 0;
								}
							}
						}
					}

					.tab-content {
						margin-top: 20px;
						background: #fff;
						border-radius: 4px;

						.tab-pane {
							padding: 15px;

							.card-btn-group {
								padding: 0 0 15px;

								.btn-card {
									margin: 0 12px;
									width: 31%;
									font-size: 14px;
									background: #e4ffaf;
									border: 1px solid #e6edd7;

									.C-text {
										margin: 0;
										background: transparent;
										width: auto;
										height: auto;
										color: #149c4a;
										font-size: 14px;
									}

									span {
										margin: 0 5px;
										color: #149c4a;
										font-size: 12px;
										display: inline-block;
										width: 24px;
										height: 24px;
										line-height: 24px;
										background: #fff;
										border-radius: 50%;
									}

									&:first-child {
										margin: 0;
									}

									&:last-child {
										margin: 0;
									}

									&:hover {
										background: #dcf6a9;
									}

									@media (max-width: 768px) {
										margin: 0 0 10px !important;
										width: 100%;

										&:last-child {
											margin: 0 !important;
										}
									}
								}
							}
						}
					}

					.title {
						margin: 50px 0 20px;
						color: #149c4a;
						font-size: 28px;
						text-align: center;
						text-transform: uppercase;
					}

					.fuelling {
						margin: 0;
						padding: 0;
						list-style: none;

						li {
							float: left;
							display: inline-block;
							width: 25%;
							text-align: center;

							a {
								color: #a6a7a9;
								font-size: 18px;
								font-weight: 500;

								.icon {
									margin: 0 auto 10px;
									background: #fff;
									width: 40px;
									height: 40px;
									line-height: 40px;
									border-radius: 50%;
								}

								span {
									color: #6f6e70;
								}
							}
						}

						@media (max-width: 768px) {
							li {
								margin: 10px 0;
								width: 100%;
							}
						}
					}

					a.mop-box {
						margin: 20px 0 0;
						padding: 10px;
						display: block;
						background: #fff;
						color: #a8a8a8;
						font-size: 14px;
						text-align: center;
						text-transform: uppercase;
						border: 2px solid #fff;
						border-radius: 4px;
						cursor: pointer;

						.icon {
							margin: 0 auto 10px;
							width: 38px;
							height: 28px;
							text-align: center;
							overflow: hidden;

							&.cash {
								background: url(../../img/icon/cash.png) no-repeat;
								background-position: 0 0;
								overflow: hidden;
							}

							&.credit {
								background: url(../../img/icon/credit.png) no-repeat;
								background-position: 0 0;
								overflow: hidden;
							}

							&.fleet, &.ecom, &.sap {
								background: url(../../img/icon/fleet.png) no-repeat;
								background-position: 0 0;
								overflow: hidden;
							}

							&.pump {
								background: url(../../img/icon/pump.png) no-repeat;
								background-position: 0 0;
								overflow: hidden;
							}

							&.own-use {
								background: url(../../img/icon/own-use.png) no-repeat;
								background-position: 0 0;
								overflow: hidden;
							}

							&.voucher, &.clm-voucher, &.voucher-redemption {
								background: url(../../img/icon/voucher.png) no-repeat;
								background-position: 0 0;
								overflow: hidden;
							}

							&.dealer-cr {
								background: url(../../img/icon/dealer-cr.png) no-repeat;
								background-position: 0 0;
								overflow: hidden;
							}
						}

						span {
							display: block;
						}

						&:hover,
						&:focus {
							color: #149c4a;
							border-color: #149c4a;

							.cash, .credit, .fleet, .ecom, .sap, .pump, .own-use, .voucher, .clm-voucher, .voucher-redemption, .dealer-cr {
								background-position: 0 -28px;
							}
						}
					}
				}
			}

			.pro-tile {
				position: relative;
				margin-bottom: 13px;
				padding: 20px 10px;
				background: #d3d9db;
				text-align: center;
				border-radius: 4px;

				.num {
					position: absolute;
					left: 0;
					right: 0;
					bottom: -13px;
					margin: 0 auto;
					background: #149c4a;
					width: 26px;
					height: 26px;
					color: #fff;
					font-size: 12px;
					font-weight: 400;
					line-height: 27px;
					border-radius: 50%;
					z-index: 9;
				}

				&.pro-tile-white {
					background: #fff;

					.num {
						bottom: -15px;
						width: 30px;
						height: 30px;
						font-size: 14px;
						line-height: 30px;
						color: #149c4a;
						background: #fff;
						border: 1px solid #d9d9d9;
					}

					.price {
						position: absolute;
						left: 0;
						right: 0;
						bottom: -22px;

						.left {
							width: 50%;
							float: left;

							p {
								margin: 0;
								padding: 0;
								font-size: 14px;
								font-weight: 500;
								text-align: left;
							}
						}

						.right {
							width: 50%;
							float: right;

							p {
								margin: 0;
								padding: 0;
								font-size: 14px;
								font-weight: 500;
								text-align: right;
							}
						}
					}
				}

				&.disable {
					background: #d3d9db;
					cursor: no-drop;

					&:hover {
						background: #d3d9db;
					}
				}

				&:hover {
					background: #e3e9ea;
				}
			}

			a.serv-tile {
				margin-bottom: 10px;
				padding: 6px;
				display: block;
				background: #149c4a;
				color: #fff;
				font-size: 13px;
				font-weight: 400;

				&:hover,
				&:focus,
				&.active {
					background: #9aca3c;
				}

				@media (max-width: 768px) {
					margin-top: 10px;
				}
			}
		}

		.sub-footer {
			padding: 15px;
			background: #eaeaea;

			p {
				position: relative;
				margin: 0 0 10px;
				padding: 0;
				color: #7e7e7e;
				font-size: 18px;
				font-weight: 500;

				span {
					position: absolute;
					right: 0;
					display: inline-block;
					width: 100px;
					text-align: right;

					i {
						margin: 3px 10px 3px 0;
					}
				}

				&:last-child {
					margin: 0;
				}
			}
		}

		.card-footer {
			background: #797979;
			border: none;
			border-radius: 0;

			p {
				position: relative;
				margin: 0;
				padding: 0;
				color: #fff;
				font-size: 20px;
				font-weight: 500;
				line-height: 20px;
				text-transform: uppercase;

				span {
					position: absolute;
					right: 0;

					i {
						margin-right: 8px;
					}
				}
			}
		}
	}

	@media (max-width: 1366px) {
		.info {
			.card-header {
				padding-bottom: 0;

				.input-group {
					margin-bottom: 0 !important;
				}
			}

			.customer-details {
				p {
					padding: 0 0 6px;
					font-size: 16px;
				}
			}
		}

		.product {
			.card-header {
				padding-bottom: 0;
			}
		}

		.fuel {
			h4 {
				font-size: 26px;
			}

			.fuel-box {
				.category {
					text-align: center;

					.icon {
						margin: 17px auto;
						width: 40px;
					}

					h4 {
						margin: 0 0 5px;
						font-size: 18px;
					}

					p {
						margin: 0 0 3px;
						font-size: 16px;
					}
				}
			}
		}

		.payment {
			.card-header {
				padding-bottom: 0;
			}

			.payment-details {
				height: 60px;

				ul {
					li {
						.pro-details {
							margin: 0 0 0 100px;

							img {
								left: -100px;
								width: 80px;
								height: 80px;
							}

							h4 {
								margin: 0 0 34px;
								font-size: 18px;

								span {
									font-size: 18px;
								}
							}

							.input-group {
								width: 72px;

								input {
									font-size: 14px;
									width: 24px;
									height: 24px;
								}

								.input-group-prepend {
									.btn {
										width: 24px;
										height: 24px;
										font-size: 10px;
										line-height: 21px;
									}
								}
							}

							.badge {
								position: absolute;
								left: 90px;
								bottom: 2px;
								padding: 4px 6px;
								font-size: 12px;
								font-weight: normal;
								background: #ff3535;
								border-radius: 0;
							}
						}
					}
				}
			}
		}

		.offer {
			img {
				max-height: 250px;
			}
		}

		.calculator {
			.calculator-screen {
				height: 80px;
				line-height: 80px;
			}

			.calculator-keys {
				button {
					height: 55px;
					font-size: 18px;
				}
			}
		}

		.payment-option {
			button {
				padding: 10px;
				height: 55px;
				font-size: 14px;
			}
		}

		.card {
			.card-header {
				h4 {
					font-size: 22px;
				}
			}

			.sub-footer {
				p {
					margin: 0 0 5px;
					font-size: 16px;
				}
			}
		}
	}

	@media (max-width: 1024px) {
		.info {
			.card-header {
				padding-bottom: 0;

				.input-group {
					margin-bottom: 0 !important;
				}
			}

			.customer-details {
				p {
					margin: 0 0 10px;
					padding: 0 0 10px;
					font-size: 14px;
					border-bottom: 1px solid #dadfe3;

					span {
						position: relative;
						left: 10px;

						&.last {
							position: relative;
							left: 0;
							display: block;
							color: #000;
							text-align: left;
						}
					}

					&:last-child {
						margin: 0;
						padding: 0;
						border: none;
					}
				}
			}
		}

		.product {
			.card-header {
				padding-bottom: 0;
			}
		}

		.fuel {
			h4 {
				font-size: 18px;
			}

			.fuel-box {
				.category {
					text-align: center;

					.icon {
						margin: 16px auto;
						width: 40px;
					}

					h4 {
						margin: 0 0 5px;
						font-size: 18px;
					}

					p {
						margin: 0 0 3px;
						font-size: 16px;
					}
				}
			}
		}

		.payment {
			.card-header {
				padding-bottom: 0;
			}

			.payment-details {
				height: 60px;

				ul {
					li {
						.pro-details {
							margin: 0 0 0 100px;

							img {
								left: -100px;
								width: 80px;
								height: 80px;
							}

							h4 {
								margin: 0 0 9px;
								font-size: 16px;

								span {
									position: relative;
									margin-top: 9px;
									display: block;
									font-size: 16px;
								}
							}

							.input-group {
								width: 72px;

								input {
									font-size: 14px;
									width: 24px;
									height: 24px;
								}

								.input-group-prepend {
									.btn {
										width: 24px;
										height: 24px;
										font-size: 12px;
										line-height: 18px;
									}
								}
							}

							.badge {
								position: absolute;
								left: 90px;
								bottom: 2px;
								padding: 4px 6px;
								font-size: 12px;
								font-weight: normal;
								background: #ff3535;
								border-radius: 0;
							}
						}
					}
				}
			}
		}

		.offer {
			img {
				max-height: 100px;
			}
		}

		.calculator {
			.calculator-keys {
				button {
					height: 47px;
					font-size: 18px;
				}
			}
		}

		.payment-option {
			button {
				padding: 23px 10px;
				height: auto;
				font-size: 12px;
				line-height: 12px;
				text-align: center;

				i {
					margin: 0 0 10px;
					display: block;
				}
			}
		}

		.card {
			.card-header {
				h4 {
					font-size: 18px;
				}
			}

			.sub-footer {
				p {
					margin: 0;
					font-size: 16px;
				}
			}

			.card-footer {
				p {
					font-size: 16px;
				}
			}
		}
	}

	@media (min-width: 600px) and (max-width: 991px) {
		.info {
			.customer-details {
				span {
					&.last {
						position: absolute !important;
						right: 0;
						display: inline-block !important;
						text-align: right !important;
					}
				}
			}
		}

		.fuel,
		.payment {
			margin-bottom: 24px;

			.payment-details {
				height: 60px;

				.pro-details {
					h4 {
						margin: 0 0 34px !important;

						span {
							position: absolute !important;
							margin: 0 !important;
						}
					}
				}
			}
		}
	}

	@media (max-width: 600px) {
		.product {
			.product-list {
				grid-template-columns: repeat(2, 1fr);
			}
		}

		.fuel,
		.payment {
			margin-bottom: 24px;
		}
	}
}

.btn-normal {
	width: auto;
}


.gap-3 {
	gap: 1em;
}
