.report-nozzle-box {
	// bottom: 20px;
	display: flex;
	flex-direction: column;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid rgba(0, 0, 0, 0.125);
	position: relative;
	justify-content: center;
	align-items: flex-start;
	height: 100px;
	margin-bottom: 25px;
	padding-left: 20px;

	.sticker {
		position: absolute;
		top: 0;
		right: 0;
		width: 0;
		height: 0;
		border-top: 90px solid #9bca3b;
		border-left: 90px solid transparent;

		&>span {
			font-weight: bold;
			font-size: 2.5rem;
			line-height: 2.75rem;
			color: #ffffff;
			position: absolute;
			bottom: 40px;
			left: -50px;
			width: 50px;
			text-align: center;
		}
	}

	&>img {
		width: auto;
		height: 70%;
	}
}

.trxn-container {
	justify-content: space-between;
	display: grid;
	// grid-template-columns: repeat(3, 1fr);
	// grid-gap: 2rem 1rem;
	grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
	grid-gap: 30px 10px;

	.trxn-item {
		background: #ffffff 0% 0% no-repeat padding-box;
		box-shadow: 0px 0px 10px #00000017;
		min-width: 220px;
		height: 200px;
		// margin: 0 auto 0 0;
		padding: 8px 18px 12px 18px;
		position: relative;
		cursor: pointer;

		.sticker {
			position: absolute;
			right: 0;
			top: 0;
			width: 0;
			height: 0;
			border-left: 76px solid transparent;

			&>span {
				font-weight: bold;
				font-size: 16px;
				line-height: 19px;
				color: #ffffff;
				position: absolute;
				top: -64px;
				left: -47px;
				width: 35px;
				text-align: center;
			}
		}

		&.red {
			.sticker {
				border-top: 73px solid #cc262699;
			}
		}

		&.green {
			.sticker {
				border-top: 73px solid #9bca3b;
			}
		}

		&.blue {
			.sticker {
				border-top: 73px solid #2926cc7a;
			}
		}

		&>h4 {
			font-weight: 500;
			font-size: 17px;
			line-height: 30px;
			letter-spacing: 0px;
			color: #00419b;
			margin-bottom: 0;
		}

		&>p {
			font-size: 15px;
			line-height: 21px;
			letter-spacing: 0px;
			color: black;
			opacity: 1;
			align-self: center;
			margin: 15px 0 0 0;
		}

		&>i {
			width: 82px;
			height: 70px;
			overflow: hidden;
			align-self: center;
			margin: 10px 0 0 0;

			&.cash {
				background: url(../../img/icon/cash.svg) no-repeat bottom;
			}

			&.credit {
				background: url(../../img/icon/credit.svg) no-repeat bottom;
			}

			&.fleet,
			&.ecom, &.sap {
				background: url(../../img/icon/fleet.svg) no-repeat bottom;
			}

			&.pump {
				background: url(../../img/icon/pump_test.svg) no-repeat bottom;
			}

			&.own-use {
				background: url(../../img/icon/own_use.svg) no-repeat bottom;
			}

			&.voucher, &.clm-voucher, &.voucher-redemption {
				background: url(../../img/icon/voucher.svg) no-repeat bottom;
			}

			&.ags {
				background: url(../../img/icon/ags.png) no-repeat bottom;
			}

			&.paytm {
				background: url(../../img/icon/paytm.svg) no-repeat bottom;
			}

			&.dealer-cr {
				background: url(../../img/icon/dealer.svg) no-repeat bottom;
			}
		}

		.txn-item-lastrow {
			display: flex;
			justify-content: space-between;
			align-items: center;

			&>p {
				text-align: left;
				font-size: 12px;
				line-height: 16px;
				letter-spacing: 0px;
				color: #9b9b9b;
				opacity: 1;
				margin: 13px 0 0 0;
				flex-basis: 70px;
			}

			&>h6 {
				text-align: right;
				font-weight: 500;
				font-size: 18px;
				line-height: 21px;
				letter-spacing: 0px;
				color: #139c4a;
				opacity: 1;
				margin: 10px 0 0 0;
				width: 66px;
				flex-grow: 1;
			}
		}
	}

	.trxn-item:hover {
		background-color: white;
		opacity: 0.8;
	}
}

@media (min-width: 992px) {
	.report-noxzzle-fixed {
		position: fixed;
		width: calc(16.66667% - 30px);
	}

	.report-nozzle-box {
		height: calc(100vh - 100px);
		margin-bottom: 0;
		padding-left: 0;
		align-items: center;

		&>img {
			width: 70%;
		}
	}
}
