@mixin hero-color($bgcolor, $color) {
	.n-txn-modal-hero {
		.n-txn-modal-hero-bg {
			&:after {
				background: $bgcolor;
			}
		}

		.n-txn-modal-hero-fixed {
			.n-txn-modal-hero-circle {
				background: $color;
			}
		}
	}
}

.n-txn-modal-content {
	width: 30rem;

	.n-txn-modal-hero {
		height: 15rem;
		position: relative;

		.n-txn-modal-hero-bg {
			overflow: hidden;
			height: 10rem;

			&:after {
				content: "";
				height: 40rem;
				width: 40rem;
				display: block;
				position: relative;
				bottom: 482px;
				right: 80px;
				background: #e9e9e9; // color
				border-radius: 47%;
			}
		}

		.n-txn-modal-hero-fixed {
			position: absolute;
			width: 10rem;
			height: 10rem;
			left: 50%;
			top: 80px;
			transform: translate(-50%, 0);

			.n-txn-modal-hero-circle {
				width: 100%;
				height: 100%;
				background: #1c241e; // color
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;

				.n-txn-modal-hero-fpno {
					text-align: center;
					font-size: 3rem;
					font-weight: bold;
					color: #fff;
				}
			}
		}
	}

	@each $name,
	$bgcolor,
	$color in ("HSD", #b1f9d6, #00a655),
	("MS", #b1d1ff, #00419b),
	("ALPG", #fbb7b6, #cc1a19) {
		&.#{$name} {
			@include hero-color($bgcolor, $color)
		}
	}

	.n-txn-modal-details {
		padding: 30px;
		display: flex;
		flex-direction: column;
		color: #141414;

		h2 {
			font-size: 2rem;
			font-weight: 900;
		}

		h4 {
			color: rgba(20, 20, 20, 0.65);
		}

		.n-txn-modal-details-label {
			color: rgba(20, 20, 20, 0.65);
			margin: 0;
		}
	}
}
