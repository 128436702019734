/* Home page */
.og-fueling {
	display: flex;
	flex-direction: column;
	padding: 10px;
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 0px 10px #00000017;
	margin-top: 10px;
}

/* Home page end */

.link-btn {
	padding: 0 2px;
	margin: 0;
	width: auto;
	color: #212529;
	background: none;
	border-bottom: 1px dotted #212529;
}

.icon-btn-big {
	width: 307px;
	height: 333px;
	background: #ffffff;
	color: #707070;
	font-size: 24px;
	line-height: 28px;
	letter-spacing: 0.24px;
	border-radius: 0;
	padding: 91px 30px 102px 30px;

	&.mh-30 {
		margin: 0 30px;
		padding: 30px;
	}

	&.primary {
		color: #fff;
		background: #08b571;
	}

	&.primary:hover {
		color: #fff;
	}

	&>img {
		display: block;
		width: 133px;
		height: 138px;
		margin: auto auto 40px;
	}

	span {
		font-family: $font-family-base;
		font-weight: $font-weight-bold;
	}
}

.fuelsale-body {
	min-height: calc(100vh - 231px);
}

.order-container {
	justify-content: space-between;
	display: grid;
	grid-template-columns: repeat(auto-fill, 253px);
	grid-gap: 3rem 1rem;
	padding: 0 90px;

	.order-item-w {
		.order-item {
			background: #ffffff 0% 0% no-repeat padding-box;
			box-shadow: 0px 0px 10px #00000017;
			height: 205px;
			width: 253px;
			margin: 0 auto;
			text-align: right;
			padding: 15px 13px;
			word-wrap: break-word;

			&>h4 {
				font-weight: 500;
				font-size: 50px;
				line-height: 58px;
				letter-spacing: 0px;
				color: #00419b;
			}

			&>p {
				font-weight: 500;
				font-size: 30px;
				line-height: 35px;
				letter-spacing: 0px;
				color: #5a5a5a;
				margin-bottom: 0;
			}
		}

		.order-item.selected {
			background: var(--green);
			color: white;

			&>h6,
			&>p {
				color: white;
			}
		}
	}
}

.float-nav {
	// justify-content: space-between;
	// display: flex;
	// position: fixed;
	// top: 50%;
	// width: calc(100% - 105px);
	// transform: translateY(-50%);

	.round-arrow {
		width: 76px;
		height: 76px;
		border-radius: 50%;
		opacity: 0.6;
		font-size: 30px;
	}
}

div.swipe-fleet.card-square {
	font-size: 1.75rem;
	line-height: 2rem;
	width: 955px;
	height: 507px;

	button.btn.btn-xl {
		color: white;
	}
}

.mdu-vehicle-card, .swipe-fleet {
	&.card-square {
		flex-direction: column;
		padding: 188px;
		width: 755px;
		height: 407px;
		align-items: stretch;
		justify-content: center;
		margin: auto;

		&.small-padding {
			padding: 100px;
		}
	}

	&>button {
		margin-top: 46px;

		&:hover {
			color: #ffffff;
		}
	}
}

.pfc-o-detail-card {
	margin: 0 80px;
}

.footer-btn-item {
	flex: 1 1 0px;

	.footer-btn {
		height: 137px;
		padding-top: 43px;

		&>img {
			width: 36px;
			height: 36px;
			margin-bottom: 7px;
		}

		&>span {
			font-weight: medium;
			font-size: 15px;
			line-height: 18px;
			letter-spacing: 0px;
		}
	}
}

.pump-card {
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 0px 10px #00000017;
	height: 392px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex-direction: column;
	position: relative;
	padding: 0 30px;

	&>img {
		width: 134px;
		margin-bottom: 90px;
	}

	.round-b-text {
		margin-bottom: 12px;
		text-transform: uppercase;
	}
	.round-b-text.primary {
		border: 0.5px solid var(--green);
		color: #fff;
		background: var(--green);
	}

	.sticker {
		position: absolute;
		right: 0;
		top: 0;
		width: 0;
		height: 0;
		border-top: 108px solid #9bca3b;
		border-left: 104px solid transparent;

		&>span {
			font-weight: bold;
			font-size: 24px;
			line-height: 28px;
			letter-spacing: 0.24px;
			color: #ffffff;
			position: absolute;
			top: -88px;
			left: -47px;
			width: 35px;
			text-align: center;
		}
	}

	&.red {
		.sticker {
			border-top: 108px solid #cc2626;
		}
	}

	&.green {
		.sticker {
			border-top: 108px solid #9bca3b;
		}
	}

	&.blue {
		.sticker {
			border-top: 108px solid #2926cc;
		}
	}

	&.yellow {
		.sticker {
			border-top: 108px solid #ccbb26;
		}
	}

	&.black {
		.sticker {
			border-top: 108px solid #413c3c;
		}
	}

	&.selected {
		.round-b-text {
			border: 0.5px solid #9bca3b;
			color: #fff;
			background: #9bca3b;
		}
	}

	&.disabled {
		opacity: 0.7;
	}
}

.fp-container {
	display: grid;
	grid-template-columns: repeat(auto-fill, 246px);
	grid-gap: 9px 10px;
	justify-content: center;

	.fp-item {
		&.pump-card {
			width: 247px;
			height: 288px;

			&>img {
				margin-bottom: 43px;
			}

			.round-b-text {
				text-transform: none;
			}
		}
	}
}

.swipe-fleet-container {
	// display: flex;
	width: 100%;
	// flex-wrap: wrap;
	margin: auto;
	// justify-content: center;
	text-align: center;
}

.mop-container {
	padding: 0 90px;

	.icon-card {
		height: 150px;

		&.selected {
			.icon {
				&.cash {
					background: url(../../img/icon/cash_ontouch.svg) no-repeat bottom;
				}

				&.credit {
					background: url(../../img/icon/credit_ontouch.svg) no-repeat bottom;
				}

				&.fleet, &.ecom, &.sap {
					background: url(../../img/icon/fleet_ontouch.svg) no-repeat bottom;
				}

				&.pump {
					background: url(../../img/icon/pump_test_ontouch.svg) no-repeat bottom;
				}

				&.own-use {
					background: url(../../img/icon/own_use_ontouch.svg) no-repeat bottom;
				}

				&.voucher, &.clm-voucher, &.voucher-redemption {
					background: url(../../img/icon/voucher_ontouch.svg) no-repeat bottom;
				}

				&.ags {
					background: url(../../img/icon/ags_ontouch.png) no-repeat bottom;
				}

				&.paytm {
					background: url(../../img/icon/paytm_ontouch.svg) no-repeat bottom;
				}

				&.dealer-cr {
					background: url(../../img/icon/dealer_ontouch.svg) no-repeat bottom;
				}
			}
		}

		.icon {
			width: 50px;
			height: 60px;
			background-size: 100% 100% !important;
			overflow: hidden;

			&.cash {
				background: url(../../img/icon/cash.svg) no-repeat bottom;
			}

			&.credit {
				background: url(../../img/icon/credit.svg) no-repeat bottom;
			}

			&.fleet, &.ecom, &.sap {
				background: url(../../img/icon/fleet.svg) no-repeat bottom;
			}

			&.pump {
				background: url(../../img/icon/pump_test.svg) no-repeat bottom;
			}

			&.own-use {
				background: url(../../img/icon/own_use.svg) no-repeat bottom;
			}

			&.voucher, &.clm-voucher, &.voucher-redemption {
				background: url(../../img/icon/voucher.svg) no-repeat bottom;
			}

			&.ags {
				background: url(../../img/icon/ags.png) no-repeat bottom;
			}

			&.paytm {
				background: url(../../img/icon/paytm.svg) no-repeat bottom;
			}

			&.dealer-cr {
				background: url(../../img/icon/dealer.svg) no-repeat bottom;
			}
		}
	}
}

.volume-container {
	// display: grid;
	justify-content: center;
	width: 100%;
	flex-wrap: wrap;
	margin: auto;

	flex-direction: column;

	.volume-types {
		margin-bottom: 40px;
		text-align: center;
	}

	.icon-card:disabled {
		display: none;
	}

	.icon-card {
		&.selected {
			.icon {
				&.amount {
					background: url(../../img/icon/cash_ontouch.svg) no-repeat bottom;
				}

				&.top-up {
					background: url(../../img/icon/own_use_ontouch.svg) no-repeat bottom;
				}

				&.volume {
					background: url(../../img/icon/volume_w.svg) no-repeat bottom;
				}
			}
		}

		.icon {
			width: 70px;
			height: 58px;
			overflow: hidden;

			&.top-up {
				background: url(../../img/icon/own_use.svg) no-repeat bottom;
			}

			&.volume {
				background: url(../../img/icon/volume_g.svg) no-repeat bottom;
			}

			&.amount {
				background: url(../../img/icon/cash.svg) no-repeat bottom;
			}
		}

		span {
			font-size: 1.5rem;
			line-height: 1.75rem;
		}
	}

	.volume-input {
		width: 462px;
		margin: 0 auto;
		margin-bottom: 14px;
	}

	.volume-btn {
		width: 271px;
		height: 110px;
		margin-top: 53px;
		margin: auto;
	}
}

.order-summery {
	flex-direction: column;

	.summery-btn {
		width: 271px;
		height: 110px;
		margin-top: 86px;
	}
}

.fueling-container {
	padding-left: 95px;
	padding-right: 95px;

	.pump-card {
		height: 308px;

		img {
			margin-bottom: 35px;
			margin-top: 15px
		}
	}

	.fueling-title {
		font-weight: bold;
		font-size: 26px;
		line-height: 30px;
		letter-spacing: 0px;
		color: #000000;
		text-transform: uppercase;
		text-align: center;
	}

	.fueling-proceed-btn {
		width: 271px;
		height: 110px;
		margin-top: 75px;
	}

	.processing-msg {
		button {
			width: auto;
		}

		.msg-text {
			margin-right: 20px;
		}
	}

	.ecom-qty {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 0 !important;

		.font-digital {
			font-size: 3rem;
			line-height: 3.25rem;
		}

		small {
			font-family: $font-family-base;
			color: $gray-600;
			font-size: 1rem;
			line-height: 1.25rem;
		}
	}
}

.txn-progress {
	background-color: transparent;
	position: absolute;
	height: 3px;
	width: 100%;
	top: 0;
	left: 0;

	.txn-progress-bar {
		background: linear-gradient(to left, #00a757, #36d387, #52e09b);
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		width: 100%;
		opacity: 1;
		transition: 0.5s linear 0.3s;
	}
}

.display-card {
	position: relative;
	width: 100%;
	height: 98px;
	justify-content: space-between;
	flex-direction: row;
	padding: 20px 15px;
	background: #fff;
	box-shadow: 0px 0px 10px #00000017;
	align-items: center;
	display: flex;
	margin: 10px 0;

	.icon-block {
		flex-basis: 100px;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		display: flex;
		margin-right: 30px;

		.icon {
			width: 50px;
			height: 45px;
			background-size: 100% 100% !important;
			overflow: hidden;
			margin-bottom: 10px;
			display: block;

			&.product {
				background: url(../../img/icon/own_use.svg) no-repeat bottom;
			}

			&.nozzle {
				background: url(../../img/icon/nozzle-small.svg) no-repeat bottom;
			}

			&.volume {
				background: url(../../img/icon/volume_g.svg) no-repeat bottom;
			}

			&.amount {
				background: url(../../img/icon/amount.svg) no-repeat bottom;
			}
		}

		&>span {
			font-weight: 400;
			font-size: 1rem;
			line-height: 1.25rem;
			color: $gray-600;
		}
	}

	.text-block {
		flex-grow: 1;
		border-left: 0.5px solid #e7e7e7;
		padding: 0 30px;
		height: 100%;
		display: flex;
		align-items: center;

		&>strong {
			letter-spacing: 0.15rem;
			color: #000000;
			font-size: 5rem;
			line-height: 5.25rem;
			font-family: $font-family-digital;
			font-weight: normal;
		}
	}
}

.font-digital {
	font-family: $font-family-digital;
}

.ags-terminal-card {
	&.card-square {
		width: 100%;
		background: #f7f7f7;
		padding: 30px;
	}

	.terminal-item {
		height: 250px;
		box-shadow: 0px 0px 6px #00000029;
		background: #fff;
		border-top: 5px solid $yellow;
		padding: 30px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		&>img {
			padding-left: 30px;
			padding-right: 30px;
		}


		&>div>span {
			font-weight: 700;
			font-size: 2rem;
			font-family: $font-family-base;
			color: #999999;
		}
	}
}

.ags-status {
	width: 700px;
	margin: auto;
	height: 638px;
	align-items: center;
	display: flex;
	justify-content: center;
	flex-direction: column;
}

/* Common CSS */

.round-b-text {
	border: 0.5px solid #707070;
	font-size: 24px;
	line-height: 28px;
	letter-spacing: 0.24px;
	color: #676767;
	border-radius: 30px;
	text-align: center;
	padding: 10px;
	width: 100%;
}

.form-group {
	&.float-label {
		position: relative;

		&>label {
			top: 14px;
			left: 20px;
			position: absolute;
			background-color: #fff;
			padding: 0px 5px 0px 5px;
			font-size: 14px;
			transition: 0.2s;
			pointer-events: none;
		}

		.form-control {
			&:focus~label,
			&:valid~label,
			&:read-only~label {
				top: -2px;
				font-size: 12px;
				color: #707070;
			}
		}
	}
}

.form-group {
	&.u-line-input {
		position: relative;

		&>label {
			top: 14px;
			left: 0;
			position: absolute;
			background-color: #fff;
			font-size: 14px;
			transition: 0.2s;
			pointer-events: none;
		}

		.form-control {
			border: none;
			border-bottom: 1px solid #ccc;
			border-radius: 0;
			padding: 12px 0;

			&:focus~label, &:valid~label {
				top: -2px;
				font-size: 12px;
				color: #707070;
			}
		}
	}
}

.card-square {
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 2px 6px #0000000a;
	border: 1px solid #caced5;
	display: flex;
	padding: 20px;
}

.btn-xl {
	padding: 43px;
	font-weight: bold;
	font-size: 2rem;
	line-height: 2.25rem;
	letter-spacing: 0px;
	color: #676767;

	&.primary {
		background: #9bca3b 0% 0% no-repeat padding-box;
		color: #ffffff;
	}

	&.d-green {
		background: #08b571 0% 0% no-repeat padding-box;
		color: #ffffff;
	}

	&.box-shadow {
		box-shadow: 0px 0px 10px #00000017;
	}
}

.theme-grp-btn {
	&.input-group {
		.form-control {
			height: 67px;
			border-radius: 0;
			border: 1px solid #cbcbcb;
			font-size: 52px !important;
		}

		.input-group-text {
			font-size: 18px;
			line-height: 21px;
			letter-spacing: 0px;
			color: #ffffff;
			background: #08b571 0% 0% no-repeat padding-box;
			min-width: 79px;
			height: 67px;
			text-align: center;
			align-items: center;
			justify-content: center;
			border-radius: 0;
			border: 1px solid #08b571;
		}
	}
}

.icon-card {
	width: 198px;
	height: 228px;
	background: #fff;
	box-shadow: 0px 0px 10px #00000017;
	justify-content: center;
	align-items: center;
	// display: flex;
	flex-direction: column;
	margin: 13px 15px;
	display: inline-flex;
	margin-bottom: 10px;

	&>span {
		font-size: 1.25rem;
		line-height: 1.5rem;
		color: #676767;
		font-family: $font-family-base;
		font-weight: $font-weight-bold;
	}

	&.selected {
		background: #08b571;

		&>span {
			color: #fff;
		}
	}

	.icon {
		margin-bottom: 18px;
	}
}

.btm-ptn {
	bottom: 0px;
}

.pstn-rel {
	position: relative;
}

.scrl {
	overflow-y: scroll;
	max-height: 350px;
}

.fontstr {
	font-size: 20px;
}

.algnmnt {
	text-align: center;
}

.card-summery {
	margin: auto;
}

.float-label {
	& .sumerycs {
		font-weight: 500;
		font-size: 17px;
	}

	&>span {
		font-weight: 500;
		font-size: 17px;
	}
}

.horzontal-line {
	border: 1px dashed #000;
	width: 100%;
	left: 0;
}

.zig-zag-top:before {
	background: linear-gradient(-45deg, #fff 16px, red 16px, blue 16px, transparent 0),
		linear-gradient(45deg, #fff 16px, transparent 0);
	background-position: left top;
	background-repeat: repeat-x;
	background-size: 22px 32px;
	content: ' ';
	display: block;

	height: 32px;
	width: 100%;

	position: relative;
	bottom: 64px;
	left: 0;
}

.zig-zag-bottom {
	margin-bottom: 10px;
	margin-top: 0;
	background: #fff;
}

.zig-zag-top {
	margin-bottom: 0;
	margin-top: 20px;
	background: #fff;
}

.zig-zag-bottom,
.zig-zag-top {
	padding: 35px 0;
}

.zig-zag-bottom:after {
	background: linear-gradient(-45deg, transparent 16px, #fff 0),
		linear-gradient(45deg, transparent 16px, #fff 0);
	background-repeat: repeat-x;
	background-position: left bottom;
	background-size: 22px 32px;
	content: '';
	display: block;

	width: 100%;
	height: 32px;

	position: relative;
	top: 64px;
	left: 0px;
}

.pump-card-img {
	&.pump-card {
		&>img {
			width: 110px;
		}

		.sticker {
			border-top: 83px solid #9bca3b;
			border-left: 86px solid transparent;

			&>span {
				top: -70px;
				left: -45px;
			}
		}
	}
}

.gray-scal {
	filter: grayscale(1);
}

@media (min-width: 992px) {
	.display-container {
		margin-top: -10px;
	}
}

.ecom-sale-list {
	background: white;
	width: 100%;
	font-size: 20px;

	thead {
		position: sticky;
		top: 60px;
		left: 0;
		border-bottom: 3px solid;
		background: #149c4a;
		color: white;
	}

	tbody {
		tr {
			border-bottom: 1px solid;
			background: white;
		}

		tr.active {
			background: #149c4a;
		}
	}

	th, td {
		padding: 2rem 1rem;
	}
}
