.modal-container {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(255, 255, 255, 0.9);
	z-index: 1000;

	.modal-box {
		position: fixed;
		top: 50%;
		left: 50%;
		min-width: 300px;
		transform: translate(-50%, -50%);
		background-color: $white;
		z-index: 1050;
		box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);

		&>i {
			position: absolute;
			top: 0.5rem;
			right: 0.5rem;
		}

		.modal-header {
			padding: 0.8rem 1rem;
		}

		.modal-body {
			min-height: 8rem;
		}
	}

	.spinner-container {
		display: flex;
		flex-direction: row;
		align-items: center;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 1050;
		background-color: white;
		color: $dark;
		box-shadow: 0 10px 25px 0 rgba(50, 50, 93, 0.07), 0 5px 15px 0 rgba(0, 0, 0, 0.07);
		border-radius: 6px;
		max-width: 200px;
		gap: 20px;
		padding: 20px;
	}
}
