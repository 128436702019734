@font-face {
	font-family: 'icomoon';
	src: url('../fonts/icons/icomoon.eot?cuzx3q');
	src: url('../fonts/icons/icomoon.eot?cuzx3q#iefix') format('embedded-opentype'),
		url('../fonts/icons/icomoon.ttf?cuzx3q') format('truetype'),
		url('../fonts/icons/icomoon.woff?cuzx3q') format('woff'),
		url('../fonts/icons/icomoon.svg?cuzx3q#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^='icon-'], [class*=' icon-'] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	/* speak: never; */
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-menu-home:before {
	content: '\e900';
}
.icon-menu-users:before {
	content: '\e901';
}
.icon-menu-sale:before {
	content: '\e902';
}
.icon-menu-report:before {
	content: '\e903';
}
.icon-menu-calendar:before {
	content: '\e904';
}
.icon-menu-print:before {
	content: '\e905';
}
.icon-menu-logout:before {
	content: '\e906';
}
.icon-nozzle:before {
	content: '\e907';
}
